<template>
  <div v-if="dataLoaded">
    <a-row type="flex" style="margin-bottom: 12px;">
      <a-col
        v-if="isFPManager"
        :xs="{ span: 18, offset: 4 }"
        :sm="{ span: 18, offset: 4 }"
        :md="{ span: 16, offset: 4 }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 6 }"
        :xxl="{ span: 10, offset: 7 }"
      >
        <a-button
          :disabled="editingTarget !== null"
          class="btn btn-primary"
          type="primary"
          @click="addRow()"
        >
          {{ $t("form.add") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col
        :xs="{ span: 18, offset: 4 }"
        :sm="{ span: 18, offset: 4 }"
        :md="{ span: 16, offset: 4  }"
        :lg="{ span: 12, offset: 6 }"
        :xl="{ span: 12, offset: 6 }"
        :xxl="{ span: 10, offset: 7 }"
      >
        <a-table
          :columns="columns"
          :dataSource="flightPlans"
          rowKey="id"
          :pagination="pagination"
          @change="onTableChange"
        >
          <template slot="name" slot-scope="text, record, index">
            <div>
              <a-form-item
                v-if="editingTarget && record.id === editingTarget.id"
                :validate-status="
                  invalidFields.indexOf('name') === -1 ? '' : 'error'
                "
              >
                <a-input
                  ref="name"
                  :value="editingTarget.name"
                  @change="(e) => handleChange(e.target.value, index, 'name')"
                  @keyup.enter="() => save(editingTarget)"
                  @keyup.esc="() => cancel()"
                />
              </a-form-item>
              <template v-else>{{ text }}</template>
            </div>
          </template>

          <template slot="action" slot-scope="text, record">
            <div class="action-container">
              <span v-if="editingTarget && record.id === editingTarget.id">
                <a-row class="btn-form">
                  <a-col :span="12">
                    <a-button
                      @click="() => save(editingTarget)"
                      type="link"
                      class="btn btn-editing"
                      :title="$t('form.save')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="check-circle"
                      />
                    </a-button>
                  </a-col>
                  <a-col :span="12">
                    <a-button
                      @click="() => cancel()"
                      type="link"
                      class="btn btn-editing btn-cancel"
                      :title="$t('form.cancel')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="close-circle"
                      />
                    </a-button>
                  </a-col>
                </a-row>
              </span>
              <span v-else-if="editingTarget === null">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="edit" @click="edit(record)">
                      <a-icon type="edit" />{{ $t("form.edit") }}
                    </a-menu-item>
                    <a-menu-item key="delete" @click="confirmDelete(record)">
                      <a-icon type="delete" />{{ $t("form.delete") }}
                    </a-menu-item>
                    <a-menu-item
                      key="download"
                      @click="checkAndDownloadFlightplan(record.id)"
                    >
                      <a-icon type="download" />{{ $t("flightplans.download") }}
                    </a-menu-item>
                  </a-menu>
                  <a-button style="margin-left: 20px">
                    <a-icon type="more" />
                  </a-button>
                </a-dropdown>
              </span>
            </div>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "../../store/plugin/axios";
import {
  Table,
  Col,
  Row,
  Input,
  Select,
  Button,
  Form,
  Modal,
  Dropdown,
} from "ant-design-vue";
import { addApiPrefix, header, getErrorMsg } from "../../store/helpers";
import { isFPManager } from "../../utils/userAuth";

Vue.use(Table);
Vue.use(Col);
Vue.use(Row);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Form);
Vue.use(Modal);
Vue.use(Dropdown);

export default {
  name: "flightplans-form",
  data() {
    return {
      flightPlans: [],
      invalidFields: [],
      editingTarget: null,
      flightplanError: null,
      pagination: {
        defaultCurrent: 1,
        current: 1,
      },
    };
  },
  computed: {
    columns() {
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "name",
          width: "40%",
          scopedSlots: { customRender: "name" },
        },
      ];
      if (this.isFPManager) {
        columns.push({
          dataIndex: "action",
          width: "5%",
          scopedSlots: { customRender: "action" },
        });
      }
      return columns;
    },
    dataLoaded() {
      return this.$store.state.lastFetch !== null;
    },
    isFPManager() {
      return isFPManager();
    },
  },
  methods: {
    onTableChange(pagination, filter, sorter) {
      this.pagination.current = pagination.current;
    },
    getDownloadLink(flightplanId) {
      return addApiPrefix(`/flightplans/${flightplanId}/export`);
    },
    addRow() {
      this.pagination.current = 1;
      this.invalidFields = [];

      const newFp = {
        id: "new",
        name: "",
        points: [],
        segments: [],
        missions: [],
      };
      this.flightPlans.unshift(newFp);
      this.editingTarget = newFp;
      this.$nextTick(() => {
        this.$refs["name"].focus();
      });
    },
    handleChange(value, key, column) {
      if (this.editingTarget) {
        this.editingTarget[column] = value;
      }
    },
    save(target) {
      if (Object.hasOwnProperty.call(target, "id")) {
        this.invalidFields = [];
        if (target.name === "") {
          this.invalidFields.push("name");
        }
        if (this.invalidFields.length === 0) {
          let isNew = target.id === "new";
          let promise;

          if (isNew) {
            promise = this.$store.dispatch("addFlightPlan", target);
          } else {
            promise = this.$store.dispatch("updateFlightPlan", target);
          }

          promise.then(() => {
            this.editingTarget = null;
            this.refreshFlightPlansFromStore();
          });
        }
      }
    },
    cancel() {
      let isNew = this.editingTarget.id === "new";

      if (isNew) {
        this.flightPlans.shift();
      }

      this.editingTarget = null;
    },
    edit(target) {
      this.editingTarget = { ...target };
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store.dispatch("deleteFlightPlan", target).then(() => {
            if (
              target.id === this.$store.state.flightPlans.currentFlightPlanId
            ) {
              this.$store.dispatch("setCurrentFlightPlanId", null);
            }

            this.refreshFlightPlansFromStore();
          });
        },
        onCancel: () => {},
      });
    },
    refreshFlightPlansFromStore() {
      this.flightPlans = [...this.$store.state.flightPlans.flightPlans];
    },
    checkAndDownloadFlightplan(flightplanId) {
      const fpUrl = addApiPrefix(`/flightplans/${flightplanId}`);

      axios
        .get(`${fpUrl}/check`, header())
        .then(() => {
            const fileLink = document.createElement("a");
            const accessToken = this.$store.state.auth.accessToken || ''

            fileLink.href = `${fpUrl}/export?jwt=${accessToken}`;
            document.body.appendChild(fileLink);
            fileLink.click();
        })
        .catch((err) => {
          let error = getErrorMsg(err)
          Modal.error({
            title: this.$t("flightplans.flightplanError"),
            content: this.$t(`error.${error}`),
          });
        });
    },
  },
  mounted() {
    this.$store.dispatch("fetchFlightPlans").then(() => {
      this.refreshFlightPlansFromStore();
    });
  },
};
</script>

<style lang="less" scoped>
.action-container {
  padding-left: 0;
}

.btn-form {
  width: 100%;
}

.btn-editing {
  font-size: 1.5rem;
  color: #f0de00;
}

.btn-cancel {
  color: red;
}

@media screen and (max-device-width: 575px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-device-width: 576px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-device-width: 768px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.30rem;
  }
}

@media screen and (min-device-width: 992px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-device-width: 1200px) {
  .btn-form {
    height: 3.25rem;
  }
}

@media screen and (min-device-width: 1600px) {
  .btn-form {
    height: 2.5rem;
  }

  .btn-editing {
    margin-bottom: 1.35rem;
  }
}

@media screen and (min-device-width: 1800px) {
  .btn-form {
    height: 2.5rem;
    width: 75%;
  }

  .btn-editing {
    margin-bottom: 1.35rem;
  }
}
</style>
